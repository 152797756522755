import React from "react";
import { StyledHero } from "./styles";
import { HeroImage } from "assets";
import { Button } from "components";
import { scrollToFooter } from "../../helpers";

const Hero = () => {
	return (
		<StyledHero id="StyledHero" $bgimage={`url(${HeroImage})`}>
			<div className="text">
				<h1>Crafting Visions, Elevating Brands.</h1>
				<h3>Transforming Ideas into Stunning Visuals</h3>
				<div className="cta-button">
					<Button label="Contact Us" onClick={scrollToFooter} />
				</div>
			</div>
		</StyledHero>
	);
};

export default Hero;
