import React, { useState, useEffect, useRef, forwardRef } from "react";
import { StyledNavbar } from "./styles";
import { HiMenuAlt2 } from "react-icons/hi";
import { RxCross2 } from "react-icons/rx";
import { Button } from "components";
import { LogoLight } from "assets";
import ThemeSwitcher from "../themeSwitcher/ThemeSwitcher";
import { scrollToFooter } from "../../helpers";

const Navbar = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const mobileNavRef = useRef();

    const menuLinks = [
        {
            name: "Home",
            link: "#home",
        },
        {
            name: "About Us",
            link: "#about",
        },
        {
            name: "Services",
            link: "#services",
        },
    ];

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (
                (mobileNavRef.current && !mobileNavRef.current.contains(event.target) && !event.target.classList.contains("menu-btn-open")) ||
                event.target.classList.contains("")
            ) {
                setMenuOpen(false);
            }
        };

        document.addEventListener("click", handleOutsideClick);
        document.addEventListener("scroll", () => {
            setMenuOpen(false);
        });

        return () => {
            document.removeEventListener("click", handleOutsideClick);
            document.removeEventListener("scroll", () => {
                setMenuOpen(false);
            });
        };
    }, [mobileNavRef]);

    return (
        <StyledNavbar id="StyledNavbar">
            <div className="top-nav-container">
                <div className="top-nav">
                    <img src={LogoLight} alt="Zenith Logo" />
                    <div className="desktop-links">
                        {menuLinks.map((link) => (
                            <a key={link.name} href={link.link}>
                                {link.name}
                            </a>
                        ))}
                    </div>
                    <div className="button-container">
                        <ThemeSwitcher className="switch" />
                        {menuOpen ? (
                            <RxCross2
                                size={36}
                                color="#fff"
                                className="menu-btn menu-btn-open"
                                onClick={() => {
                                    setMenuOpen(false);
                                }}
                            />
                        ) : (
                            <HiMenuAlt2
                                size={36}
                                color="#fff"
                                className="menu-btn menu-btn-open"
                                onClick={() => {
                                    setMenuOpen(true);
                                }}
                            />
                        )}
                        <Button label="Contact Us" className="contact-btn white" size={10} onClick={scrollToFooter} />
                    </div>
                </div>
            </div>
            <div ref={mobileNavRef} className={`nav-menu-container ${menuOpen ? "open" : "closed"}`}>
                <div className="nav-menu">
                    <div className="mobile-links">
                        {menuLinks.map((link) => (
                            <a key={link.name} href={link.link} onClick={() => setMenuOpen(false)}>
                                {link.name}
                            </a>
                        ))}
                    </div>
                    <Button label="Contact Us" className="contact-btn white" size={10} onClick={scrollToFooter} />
                </div>
            </div>
        </StyledNavbar>
    );
};

export default Navbar;
