import React from "react";
import { StyledOtherServices } from "./styles";
import { otherServices } from "./servicesConfig";
import { OtherServiceBlock } from "components";

const OtherServices = () => {
  return (
    <StyledOtherServices>
      <div className="heading">
        <h2>Other Services</h2>
        <p>We offer more services that might suit your specific needs.</p>
      </div>
      <div className="blocks-container">
        {otherServices.map((service) => (
          <div key={service.title} className="block">
            <OtherServiceBlock info={service} />
          </div>
        ))}
      </div>
    </StyledOtherServices>
  );
};

export default OtherServices;
