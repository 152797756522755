import React from "react";
import { StyledServiceBlock } from "./styles";

const ServiceBlock = ({ info }) => {
  return (
    <StyledServiceBlock>
      <div className="icon">
        <info.icon size={20} />
      </div>

      <h3>{info.title}</h3>
      <p>{info.description}</p>
    </StyledServiceBlock>
  );
};

export default ServiceBlock;
