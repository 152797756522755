import styled from "styled-components";

const StyledFooter = styled.footer`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
    color: ${(props) => props.theme.footerMainColor};
    width: 100%;

    * {
        box-sizing: border-box;
    }

    .footer-logo-container {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }

    .footer-logo {
        width: 50%;
        height: auto;
        margin: 2rem 0;
    }

    .footer-socials-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 1rem;
        width: fit-content;
    }

    .footer-social {
        width: 35px;
        height: auto;
        margin-right: 0.75rem;
    }

    .footer-social-link {
        display: flex;
        align-items: center;
        text-decoration: none;
        color: ${(props) => props.theme.footerSocialLink};
        width: fit-content;
    }

    @media (min-width: 768px) {
        .footer-logo {
            max-width: 40%;
        }
    }

    @media (min-width: 1024px) {
        max-width: 1190px;
        flex-direction: row;
        margin: auto;

        .footer-logo-container {
            width: 70%;
            height: 100%;
            justify-content: flex-start;
            align-items: center;
        }

        .footer-logo {
            width: 100%;
            max-width: 55%;
        }

        .footer-socials-container {
            justify-content: flex-start;
            align-items: flex-start;
        }
    }
`;

const StyledFooterContainer = styled.div`
    padding-bottom: 1rem;
    width: 100%;

    .text {
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        width: 314px;
        margin: 2rem auto 0 auto;
        color: ${(props) => props.theme.footerText};
    }

    .divider {
        width: 100%;
        height: 1px;
        background-color: ${(props) => props.theme.footerDivider};
        border-radius: 10px;
    }

    @media (min-width: 768px) {
        .text {
            font-size: 14px;
            width: 580px;
        }
    }

    @media (min-width: 1280px) {
        .text {
            font-size: 16px;
            width: 1186px;
        }

        .divider {
            width: 80%;
            margin: auto;
        }
    }
`;

export { StyledFooter, StyledFooterContainer };
