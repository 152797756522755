import styled from "styled-components";

const StyledServices = styled.div`
    width: 100%;
    margin-top: 80px;
    margin-bottom: 100px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .heading {
        width: 100%;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 50px;

        h2 {
            margin: 0;
            text-align: center;
            font-size: 28px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-bottom: 10px;
        }

        p {
            margin: 0;
            text-align: center;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            max-width: 300px;
        }

        h2,
        p {
            color: ${(props) => props.theme.servicesHeadings};
        }
    }

    img {
        width: 300px;
        height: auto;
        margin-bottom: 50px;
        border-radius: 15px;
    }

    .static-site,
    .uiux {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 75px;
        max-width: 330px;

        h3 {
            margin: 0;
            text-align: center;
            font-size: 22px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-bottom: 10px;
        }

        p {
            margin: 0;
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        h3,
        p {
            color: ${(props) => props.theme.servicesHeadings};
        }

        .static-blocks,
        .uiux-blocks {
            margin-top: 20px;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 30px;
        }
    }

    .contact-button {
        margin-bottom: 50px;

        height: 53px;

        .label {
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }

    @media (min-width: 768px) {
        margin-top: 65px;
        margin-bottom: 75px;

        .heading {
            h2 {
                font-size: 36px;
            }

            p {
                font-size: 16px;
                max-width: none;
            }
        }

        img {
            width: 442px;
            height: 295px;
            box-shadow: 0px 8px 29.5px 0px rgba(0, 28, 85, 0.1);
        }

        .static-site,
        .uiux {
            max-width: 660px;

            h3 {
                font-size: 32px;
            }

            p {
                font-size: 16px;
            }

            .static-blocks,
            .uiux-blocks {
                margin-top: 30px;
                flex-direction: row;
                align-items: flex-start;
                gap: 30px;
            }
        }

        .contact-button {
            max-width: 275px;
            width: 100%;
            height: 46px;

            .label {
                font-size: 16px;
            }
        }
    }

    @media (min-width: 1280px) {
        margin-top: 100px;
        margin-bottom: 186px;

        img {
            margin-bottom: 0;
        }

        .static-site,
        .uiux {
            flex-direction: row;
            gap: 50px;
            max-width: 1190px;

            h3 {
                text-align: right;
            }

            p {
                text-align: right;
            }
        }

        .uiux {
            flex-direction: row-reverse;

            h3 {
                text-align: left;
            }

            p {
                text-align: left;
            }
        }
    }
`;

const StyledOtherServices = styled.div`
    .heading {
        width: 100%;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 50px;

        h2 {
            margin: 0;
            text-align: center;
            font-size: 28px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-bottom: 10px;
        }

        p {
            margin: 0;
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            max-width: 300px;
        }
    }

    .blocks-container {
        width: 345px;
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;

        .block {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 100%;
        }
    }

    @media (min-width: 768px) {
        .heading {
            h2 {
                font-size: 36px;
            }

            p {
                font-size: 16px;
                max-width: none;
            }
        }

        .blocks-container {
            width: 724px;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
        }
    }

    @media (min-width: 1280px) {
        .blocks-container {
            width: 1190px;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 37px;
        }
    }
`;

export { StyledServices, StyledOtherServices };
