import React from "react";
import { StyledOtherServiceBlock } from "./styles";

const OtherServiceBlock = ({ info }) => {
  return (
    <StyledOtherServiceBlock>
      <div className="icon">
        <info.icon size={20} />
      </div>

      <h3>{info.title}</h3>
      <ul>
        {info?.description?.map((line, index) => (
          <li key={index}>{line}</li>
        ))}
      </ul>
    </StyledOtherServiceBlock>
  );
};

export default OtherServiceBlock;
