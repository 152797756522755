import React from "react";
import { StyledButton } from "./styles";

const Button = ({
	label,
	onClick,
	height,
	width,
	size = 12,
	className,
	disabled,
}) => {
	return (
		<StyledButton
			onClick={onClick}
			height={height}
			width={width}
			size={size}
			className={className}
			disabled={disabled}
		>
			<span className="label">{label}</span>
		</StyledButton>
	);
};

export default Button;
