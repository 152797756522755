import styled from "styled-components";

const StyledAbout = styled.div`
    width: 100%;
    margin: 100px 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    .container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        border-radius: 16px;
		box-shadow: ${(props) => props.theme.aboutBoxShadow};
		width: 330px;
		overflow: clip;

		.image {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			flex: 0.5;

			img {
				width: 100%;
				height: auto;
			}
		}

		.text {
			flex: 0.5;
			color: ${(props) => props.theme.aboutUsText};
			font-style: normal;
			max-width: 284px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			padding: 20px;

            h1 {
                margin: 0;
                text-align: center;
                font-size: 26.724px;
                font-weight: 600;
                line-height: 31.178px;
                margin-bottom: 20px;
            }

            p {
                margin: 0;
                text-align: center;
                font-size: 14px;
                font-weight: 300;
                line-height: normal;
                margin-bottom: 10px;
            }

            .cta-button {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 0.5rem;

                .label {
                    font-size: 14px;
                }
            }
        }
    }

    @media (min-width: 768px) {
        margin: 75px 0 10px;

        .container {
            width: 695px;

            .image {
                img {
                    height: 728px;
                    width: 722px;
                }
            }

            .text {
                max-width: none;
                padding: 40px 73px;

                h1 {
                    font-size: 53.028px;
                    line-height: 61.866px;
                }

                p {
                    font-size: 17.676px;
                    margin-bottom: 18px;
                }
            }
        }
    }

    @media (min-width: 1280px) {
        margin: 176px 0 76px;

        .container {
            flex-direction: row;
            width: 1190px;
            height: 548px;

            .image {
                width: 100%;

                img {
                    height: 100%;
                    width: 100%;
                }
            }

            .text {
                max-width: none;
                padding: 34px 72px;

                h1 {
                    font-size: 44.191px;
                    line-height: 51.556px;
                    text-align: left;
                }

                p {
                    font-size: 14.73px;
                    text-align: left;
                }

                .cta-button {
                    justify-content: flex-start;
                }
            }
        }
    }
`;

export { StyledAbout };
