import styled from "styled-components";

const StyledOtherServiceBlock = styled.div`
	border-radius: 14px;
	background-color: ${(props) => props.theme.otherServiceBlockBackground};
	width: calc(100% - 26px);
	padding: 28px 13px;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	gap: 12px;
	box-shadow: ${(props) => props.theme.otherServiceBlockBoxShadow};

	.icon {
		background-color: #fff;
		border-radius: 50px;
		height: 50px;
		width: 50px;
		display: flex;
		justify-content: center;
		align-items: center;
		color: ${(props) => props.theme.otherServiceBlockIcon};
	}

	h3 {
		margin: 0 !important;
		text-align: center !important;
		font-size: 19px !important;
		font-style: normal !important;
		font-weight: 600 !important;
		line-height: normal !important;
	}

	h3,
	li {
		color: ${(props) => props.theme.otherServiceBlockText};
	}

	li {
		margin: 0 !important;
		text-align: left !important;
		font-size: 14px !important;
		font-style: normal !important;
		font-weight: 400 !important;
		line-height: normal !important;
	}

	ul {
		margin: 0;
	}

	@media (min-width: 768px) {
		width: calc(100% - 14px);
		padding: 18px 7px;

		h3 {
			font-size: 16px !important;
		}

		li {
			font-size: 12px !important;
		}

		ul {
			padding: 0 2rem;
		}
	}

	@media (min-width: 1280px) {
		width: calc(100% - 100px);
		padding: 24px 50px;
		gap: 17px;
		height: unset;

		.icon {
			height: 50px;
			width: 50px;
		}

		h3 {
			font-size: 16px !important;
		}

		li {
			font-size: 14px !important;
		}

		ul {
			padding: 0;
		}
	}
`;

export { StyledOtherServiceBlock };
