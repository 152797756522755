export const scrollToFooter = () => {
    const footer = document.getElementById("StyledFooter");
    footer.scrollIntoView({ behavior: "smooth" });
};

export const scrollToServices = () => {
    const services = document.getElementById("StyledServices");
    services.scrollIntoView({ behavior: "smooth" });
};

export const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
};
