import styled from "styled-components";

const StyledButton = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px 20px;
	border-radius: 15px;
	background: ${(props) => props.theme.ctaButton};
	color: ${(props) => props.theme.ctaButtonText};

	height: ${(props) => props.height};
	width: ${(props) => props.width};

	border: none;
	outline: none;
	box-shadow: none;

	transition: all 0.3s ease-in-out;

	.label {
		font-size: ${(props) => props.size}px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}

	&:hover {
		cursor: pointer;
		background: ${(props) => props.theme.ctaButtonHover};
	}

	&.white {
        background: #fff;
        color: #0a2472;
    }

	@media (min-width: 768px) {
		.label {
			font-size: ${(props) => props.size * 1.5}px;
		}
	}
`;

export { StyledButton };
