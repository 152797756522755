import styled from "styled-components";

const StyledContact = styled.div`
    width: 100%;
    max-width: 1190px;

    * {
        box-sizing: border-box;
    }

    .contact-area {
        width: 100%;
        margin: 0 auto;
    }

    .contact-header {
        color: ${(props) => props.theme.contactHeader};
        text-align: center;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .contact-form {
        width: 100%;
        padding: 0 1rem 1rem;
    }

    .row-1 {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        gap: 1rem;
        flex-wrap: wrap;
    }

    .name-area {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 100%;
    }

    .message-box {
        margin: 1rem 0;
    }

    .text-area {
        min-height: 10rem;
        max-width: 100%;
    }

    .input-label {
        color: ${(props) => props.theme.contactInputLabel};
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .contact-form-input {
        border-radius: 15px;
        border: 1px solid ${(props) => props.theme.contactInputBorder};
        background: ${(props) => props.theme.contactInputBackground};
        height: 50px;
        padding: 1rem;
        color: ${(props) => props.theme.contactInputText};

        &:focus,
        &:active {
            border: 1px solid #0e6ba8 !important;
        }
    }

    .contact-form-button {
        width: 100%;
        height: 53px;

        .label {
            font-size: 18px;
            text-align: center;
        }
    }

    .disabled {
        opacity: 0.8;
        cursor: not-allowed;
        pointer-events: none;
    }

    .sync-icon {
        display: block;
        font-size: 26px;
    }

    .error {
        margin-top: -0.75rem;
        font-size: 14px;
        font-weight: 500;
    }

    @media (min-width: 601px) {
        .row-1 {
            flex-wrap: nowrap;
        }
    }

    @media (min-width: 1280px) {
        .input-label {
            font-size: 18.868px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        .contact-form {
            width: 100%;
        }

        .contact-header {
            font-size: 34px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            width: 100%;
        }

        .contact-form-button {
            .label {
                font-size: 16px;
            }
        }
    }
`;

export { StyledContact };
