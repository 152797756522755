import React from "react";
import { useTheme } from "styled-components";

const Logo = ({ className }) => {
	const theme = useTheme();

	return (
		<svg
			width="605"
			height="749"
			viewBox="0 0 605 749"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<g clipPath="url(#clip0_1_220)">
				<path
					d="M603.904 407.732L509.788 203.475C507.706 198.939 501.364 198.679 498.912 203.023L486.365 225.249C485.324 227.099 482.694 227.236 481.475 225.496L404.453 115.748C401.988 112.226 396.755 112.295 394.372 115.857L320.405 226.551C318.515 229.387 322.213 232.594 324.747 230.319L397.522 165.134C400.221 162.708 404.426 163.16 406.563 166.107L467.161 250.051C467.723 250.833 467.86 251.833 467.531 252.737L433.095 347.357C431.876 350.728 436.643 352.879 438.355 349.741L491.447 252.806C493.693 248.695 499.542 248.544 502.008 252.532L599.329 410.185C601.042 412.967 605.288 410.692 603.918 407.719L603.904 407.732Z"
					fill={theme.logoColor}
				/>
				<path
					d="M386.77 151.869C385.496 152.732 383.77 152.458 382.839 151.239L317.309 65.9784C316.159 64.4848 313.898 64.4711 312.734 65.951L307.639 72.4325C306.515 73.8714 304.351 73.9125 303.159 72.5285L288.585 55.5778C287.435 54.2349 285.353 54.2349 284.202 55.5778L201.331 151.485C200.331 152.637 198.619 152.828 197.4 151.91L197.359 151.883C196.14 150.965 195.839 149.266 196.674 147.977L290.791 3.42396C293.256 -0.358086 298.831 -0.262165 301.16 3.6158L387.633 147.977C388.427 149.293 388.044 150.992 386.783 151.869H386.77Z"
					fill={theme.logoColor}
				/>
				<path
					d="M80.4223 321.184L119.091 377.942C120.556 380.093 123.885 379.367 124.337 376.805L146.349 251.148C146.404 250.819 146.513 250.504 146.678 250.216L190.976 171.875C191.88 170.272 194.044 169.902 195.427 171.136L248.163 218.178C250.684 220.425 254.368 217.26 252.519 214.423L198.77 132.082C196.277 128.259 190.606 128.423 188.346 132.397L126.953 239.952C126.789 240.24 126.679 240.542 126.624 240.857L112.845 315.182C112.324 317.964 108.543 318.43 107.365 315.867L85.1617 267.482C83.0385 262.85 76.5048 262.699 74.1762 267.235L0.825405 409.692C-0.818309 412.899 3.68821 415.639 5.77025 412.693L70.3682 321.102C72.8338 317.607 78.0115 317.662 80.4223 321.184Z"
					fill={theme.logoColor}
				/>
			</g>
			<path
				d="M54.7673 584.585H108.894V609.693H19.9958V586.374L73.7666 508.89H19.9958V483.783H108.894V507.102L54.7673 584.585Z"
				fill={theme.logoColor}
			/>
			<path
				d="M222.704 567.005H153.339C153.813 573.227 155.821 577.974 159.344 581.263C162.868 584.551 167.196 586.196 172.338 586.196C179.987 586.196 185.298 582.966 188.288 576.507H220.908C219.231 583.085 216.215 589.002 211.853 594.265C207.491 599.529 202.027 603.657 195.454 606.641C188.881 609.633 181.529 611.125 173.405 611.125C163.605 611.125 154.88 609.032 147.24 604.844C139.591 600.657 133.619 594.681 129.316 586.908C125.013 579.135 122.862 570.048 122.862 559.647C122.862 549.247 124.979 540.16 129.223 532.387C133.467 524.614 139.405 518.638 147.054 514.451C154.702 510.263 163.478 508.17 173.405 508.17C183.333 508.17 191.685 510.204 199.215 514.264C206.745 518.333 212.624 524.131 216.867 531.658C221.103 539.194 223.229 547.984 223.229 558.02C223.229 560.894 223.051 563.877 222.695 566.988L222.704 567.005ZM191.88 549.967C191.88 544.703 190.084 540.524 186.501 537.414C182.918 534.303 178.437 532.752 173.058 532.752C167.679 532.752 163.588 534.252 160.064 537.236C156.541 540.228 154.355 544.475 153.525 549.967H191.88Z"
				fill={theme.logoColor}
			/>
			<path
				d="M328.365 519.927C335.353 527.522 338.851 537.956 338.851 551.222V609.693H308.383V555.35C308.383 548.653 306.646 543.449 303.182 539.745C299.717 536.04 295.05 534.184 289.197 534.184C283.344 534.184 278.685 536.04 275.212 539.745C271.748 543.449 270.011 548.653 270.011 555.35V609.693H239.365V509.611H270.011V522.885C273.12 518.46 277.296 514.968 282.556 512.391C287.816 509.823 293.729 508.534 300.302 508.534C312.008 508.534 321.36 512.332 328.356 519.927H328.365Z"
				fill={theme.logoColor}
			/>
			<path
				d="M362.416 494.455C359.011 491.284 357.308 487.368 357.308 482.706C357.308 478.044 359.011 473.95 362.416 470.78C365.821 467.61 370.209 466.024 375.588 466.024C380.967 466.024 385.176 467.61 388.582 470.78C391.987 473.95 393.689 477.925 393.689 482.706C393.689 487.487 391.987 491.284 388.582 494.455C385.176 497.625 380.848 499.21 375.588 499.21C370.328 499.21 365.821 497.625 362.416 494.455ZM390.826 509.611V609.693H360.18V509.611H390.826Z"
				fill={theme.logoColor}
			/>
			<path
				d="M468.797 583.687V609.693H453.203C442.09 609.693 433.424 606.972 427.215 601.53C420.998 596.088 417.898 587.213 417.898 574.897V535.083H405.709V509.611H417.898V485.215H448.544V509.611H468.619V535.083H448.544V575.261C448.544 578.253 449.264 580.406 450.696 581.72C452.127 583.034 454.516 583.695 457.862 583.695H468.797V583.687Z"
				fill={theme.logoColor}
			/>
			<path
				d="M574.899 519.927C581.828 527.522 585.292 537.956 585.292 551.222V609.693H554.824V555.35C554.824 548.653 553.087 543.449 549.623 539.745C546.159 536.04 541.491 534.184 535.638 534.184C529.785 534.184 525.126 536.04 521.653 539.745C518.189 543.449 516.452 548.653 516.452 555.35V609.693H485.806V476.968H516.452V523.063C519.561 518.638 523.805 515.112 529.175 512.484C534.554 509.856 540.585 508.534 547.277 508.534C558.746 508.534 567.945 512.332 574.882 519.927H574.899Z"
				fill={theme.logoColor}
			/>
			<path
				d="M56.7748 667.689C61.6284 670.164 65.3808 673.716 68.0321 678.344C70.6749 682.972 72.0048 688.372 72.0048 694.543C72.0048 700.713 70.6834 706.071 68.0321 710.605C65.3808 715.149 61.6284 718.624 56.7748 721.04C51.9212 723.456 46.2629 724.66 39.7999 724.66H19.9958V663.976H39.7999C46.2544 663.976 51.9127 665.214 56.7748 667.689ZM56.1226 710.783C59.9004 706.944 61.7978 701.527 61.7978 694.543C61.7978 687.558 59.9089 681.997 56.1226 678.039C52.3362 674.08 46.8982 672.097 39.8084 672.097H29.9487V716.539H39.8084C46.9066 716.539 52.3447 714.615 56.1226 710.775V710.783Z"
				fill={theme.logoColor}
			/>
			<path
				d="M82.3811 668.342C81.1614 667.121 80.5515 665.604 80.5515 663.798C80.5515 661.993 81.1614 660.484 82.3811 659.255C83.6009 658.026 85.1171 657.424 86.9213 657.424C88.7256 657.424 90.1486 658.034 91.3684 659.255C92.5881 660.475 93.198 661.993 93.198 663.798C93.198 665.604 92.5881 667.113 91.3684 668.342C90.1486 669.562 88.6663 670.173 86.9213 670.173C85.1764 670.173 83.6094 669.562 82.3811 668.342ZM91.8088 676.547V724.66H81.8644V676.547H91.8088Z"
				fill={theme.logoColor}
			/>
			<path
				d="M134.17 677.988C137.05 679.471 139.303 681.319 140.929 683.532V676.547H150.967V725.448C150.967 729.873 150.035 733.814 148.172 737.281C146.308 740.748 143.623 743.469 140.099 745.444C136.576 747.419 132.374 748.411 127.487 748.411C120.973 748.411 115.56 746.885 111.257 743.825C106.954 740.765 104.506 736.62 103.93 731.381H113.79C114.544 733.882 116.162 735.908 118.635 737.451C121.108 738.993 124.056 739.765 127.495 739.765C131.51 739.765 134.754 738.544 137.228 736.094C139.701 733.653 140.938 730.101 140.938 725.439V717.404C139.252 719.675 136.965 721.583 134.085 723.125C131.205 724.668 127.936 725.439 124.268 725.439C120.084 725.439 116.255 724.38 112.79 722.252C109.326 720.125 106.599 717.158 104.591 713.343C102.584 709.529 101.584 705.206 101.584 700.374C101.584 695.543 102.584 691.262 104.591 687.541C106.599 683.82 109.335 680.921 112.79 678.852C116.255 676.784 120.075 675.75 124.268 675.75C127.987 675.75 131.29 676.496 134.17 677.979V677.988ZM138.88 691.915C137.516 689.474 135.72 687.609 133.509 686.329C131.299 685.049 128.91 684.405 126.352 684.405C123.794 684.405 121.405 685.032 119.194 686.286C116.983 687.541 115.196 689.372 113.824 691.788C112.46 694.203 111.774 697.068 111.774 700.391C111.774 703.714 112.46 706.621 113.824 709.122C115.188 711.623 116.992 713.53 119.237 714.844C121.473 716.158 123.844 716.81 126.352 716.81C128.859 716.81 131.299 716.175 133.509 714.886C135.72 713.606 137.507 711.733 138.88 709.258C140.243 706.783 140.929 703.884 140.929 700.569C140.929 697.255 140.243 694.373 138.88 691.923V691.915Z"
				fill={theme.logoColor}
			/>
			<path
				d="M164.579 668.342C163.359 667.121 162.749 665.604 162.749 663.798C162.749 661.993 163.359 660.484 164.579 659.255C165.799 658.026 167.315 657.424 169.119 657.424C170.923 657.424 172.346 658.034 173.566 659.255C174.786 660.475 175.396 661.993 175.396 663.798C175.396 665.604 174.786 667.113 173.566 668.342C172.346 669.562 170.864 670.173 169.119 670.173C167.374 670.173 165.807 669.562 164.579 668.342ZM174.007 676.547V724.66H164.062V676.547H174.007Z"
				fill={theme.logoColor}
			/>
			<path
				d="M198.69 684.667V711.3C198.69 713.106 199.113 714.403 199.952 715.183C200.79 715.971 202.23 716.361 204.272 716.361H210.379V724.66H202.527C198.046 724.66 194.615 723.608 192.227 721.515C189.838 719.421 188.652 716.013 188.652 711.3V684.667H182.977V676.547H188.652V664.586H198.69V676.547H210.379V684.667H198.69Z"
				fill={theme.logoColor}
			/>
			<path
				d="M219.239 687.549C221.247 683.828 223.983 680.929 227.439 678.861C230.903 676.793 234.723 675.759 238.916 675.759C242.694 675.759 245.998 676.504 248.818 677.988C251.639 679.471 253.892 681.319 255.578 683.532V676.547H265.615V724.66H255.578V717.497C253.892 719.769 251.597 721.659 248.683 723.176C245.769 724.685 242.457 725.448 238.738 725.448C234.605 725.448 230.827 724.388 227.396 722.261C223.966 720.133 221.247 717.166 219.239 713.352C217.232 709.537 216.232 705.214 216.232 700.383C216.232 695.551 217.232 691.271 219.239 687.549ZM253.528 691.915C252.164 689.474 250.368 687.609 248.158 686.329C245.947 685.049 243.558 684.405 241 684.405C238.442 684.405 236.053 685.032 233.842 686.286C231.632 687.541 229.844 689.372 228.472 691.788C227.108 694.203 226.422 697.068 226.422 700.391C226.422 703.714 227.108 706.621 228.472 709.122C229.836 711.623 231.64 713.53 233.885 714.844C236.121 716.158 238.493 716.81 241 716.81C243.507 716.81 245.947 716.175 248.158 714.886C250.368 713.606 252.156 711.733 253.528 709.258C254.892 706.783 255.578 703.884 255.578 700.569C255.578 697.255 254.892 694.373 253.528 691.923V691.915Z"
				fill={theme.logoColor}
			/>
			<path
				d="M288.655 660.043V724.66H278.711V660.043H288.655Z"
				fill={theme.logoColor}
			/>
			<path
				d="M390.106 663.976V724.66H380.162V683.099L361.662 724.66H354.767L336.183 683.099V724.66H326.239V663.976H336.971L358.266 711.563L379.467 663.976H390.115H390.106Z"
				fill={theme.logoColor}
			/>
			<path
				d="M446.909 704.316H410.173C410.461 708.156 411.892 711.241 414.45 713.572C417.008 715.903 420.151 717.065 423.878 717.065C429.231 717.065 433.009 714.827 435.22 710.343H445.952C444.495 714.767 441.861 718.395 438.058 721.218C434.246 724.041 429.519 725.456 423.878 725.456C419.278 725.456 415.162 724.422 411.528 722.354C407.894 720.286 405.04 717.378 402.973 713.623C400.906 709.868 399.873 705.52 399.873 700.569C399.873 695.619 400.872 691.271 402.88 687.515C404.887 683.76 407.708 680.861 411.342 678.827C414.975 676.793 419.151 675.767 423.861 675.767C428.571 675.767 432.442 676.759 435.991 678.734C439.54 680.717 442.301 683.489 444.283 687.075C446.257 690.652 447.248 694.771 447.248 699.433C447.248 701.239 447.13 702.866 446.901 704.324L446.909 704.316ZM436.872 696.28C436.812 692.61 435.508 689.677 432.95 687.465C430.392 685.252 427.215 684.15 423.437 684.15C420.007 684.15 417.068 685.244 414.628 687.422C412.189 689.601 410.732 692.559 410.266 696.289H436.88L436.872 696.28Z"
				fill={theme.logoColor}
			/>
			<path
				d="M456.718 687.549C458.726 683.828 461.462 680.929 464.918 678.861C468.382 676.793 472.236 675.759 476.48 675.759C479.622 675.759 482.723 676.445 485.772 677.81C488.821 679.175 491.252 680.997 493.057 683.269V660.043H503.094V724.66H493.057V717.412C491.43 719.743 489.177 721.659 486.297 723.176C483.417 724.685 480.114 725.448 476.395 725.448C472.211 725.448 468.382 724.388 464.918 722.261C461.453 720.133 458.726 717.166 456.718 713.352C454.711 709.537 453.711 705.214 453.711 700.383C453.711 695.551 454.711 691.271 456.718 687.549ZM491.007 691.915C489.643 689.474 487.847 687.609 485.637 686.329C483.426 685.049 481.037 684.405 478.479 684.405C475.921 684.405 473.532 685.032 471.321 686.286C469.111 687.541 467.323 689.372 465.951 691.788C464.587 694.203 463.901 697.068 463.901 700.391C463.901 703.714 464.587 706.622 465.951 709.122C467.315 711.623 469.119 713.53 471.364 714.844C473.6 716.158 475.972 716.81 478.479 716.81C480.986 716.81 483.426 716.175 485.637 714.886C487.847 713.606 489.635 711.733 491.007 709.258C492.371 706.783 493.057 703.884 493.057 700.569C493.057 697.255 492.371 694.373 491.007 691.923V691.915Z"
				fill={theme.logoColor}
			/>
			<path
				d="M516.715 668.342C515.495 667.121 514.885 665.604 514.885 663.798C514.885 661.993 515.495 660.484 516.715 659.255C517.934 658.026 519.451 657.424 521.255 657.424C523.059 657.424 524.482 658.034 525.702 659.255C526.922 660.475 527.532 661.993 527.532 663.798C527.532 665.604 526.922 667.113 525.702 668.342C524.482 669.562 523 670.173 521.255 670.173C519.51 670.173 517.943 669.562 516.715 668.342ZM526.142 676.547V724.66H516.198V676.547H526.142Z"
				fill={theme.logoColor}
			/>
			<path
				d="M538.916 687.549C540.923 683.828 543.659 680.929 547.115 678.861C550.58 676.793 554.4 675.759 558.593 675.759C562.371 675.759 565.674 676.504 568.495 677.988C571.316 679.471 573.569 681.319 575.254 683.532V676.547H585.292V724.66H575.254V717.497C573.569 719.769 571.273 721.659 568.359 723.176C565.446 724.685 562.134 725.448 558.415 725.448C554.281 725.448 550.504 724.388 547.073 722.261C543.643 720.133 540.923 717.166 538.916 713.352C536.908 709.537 535.909 705.214 535.909 700.383C535.909 695.551 536.908 691.271 538.916 687.549ZM573.205 691.915C571.841 689.474 570.045 687.609 567.834 686.329C565.623 685.049 563.235 684.405 560.677 684.405C558.119 684.405 555.73 685.032 553.519 686.286C551.308 687.541 549.521 689.372 548.149 691.788C546.785 694.203 546.099 697.068 546.099 700.391C546.099 703.714 546.785 706.621 548.149 709.122C549.513 711.623 551.317 713.53 553.561 714.844C555.798 716.158 558.169 716.81 560.677 716.81C563.184 716.81 565.623 716.175 567.834 714.886C570.045 713.606 571.832 711.733 573.205 709.258C574.568 706.783 575.254 703.884 575.254 700.569C575.254 697.255 574.568 694.373 573.205 691.923V691.915Z"
				fill={theme.logoColor}
			/>
			<defs>
				<clipPath id="clip0_1_220">
					<rect
						width="603.695"
						height="413.312"
						fill="none"
						transform="translate(0.469238 0.64209)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default Logo;
