import React from "react";
import { StyledFooter, StyledFooterContainer } from "./styles";
import { Logo } from "../../assets";
import Contact from "../../views/contact/Contact";
import { MdEmail } from "react-icons/md";
import { IoLogoFacebook } from "react-icons/io";
import { FaSquareInstagram } from "react-icons/fa6";

const Footer = () => {
    return (
        <StyledFooterContainer id="StyledFooter">
            <StyledFooter id="footer">
                <div className="footer-logo-container">
                    <Logo className="footer-logo" />
                    <div className="footer-socials-container">
                        <a className="footer-social-link" href="mailto:sales@zenithdigital.co.za" rel="noreferrer">
                            <MdEmail className="footer-social" />
                            sales@zenithdigital.co.za
                        </a>
                        <a
                            className="footer-social-link"
                            href="https://www.facebook.com/zenithdigitalmedia"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <IoLogoFacebook className="footer-social" />
                            facebook.com/zenithdigitalmedia
                        </a>
                        <a
                            className="footer-social-link"
                            href="https://www.instagram.com/zenithdigitalmedia/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <FaSquareInstagram className="footer-social" />
                            instagram.com/zenithdigitalmedia
                        </a>
                    </div>
                </div>
                <Contact />
            </StyledFooter>
            <div className="text">
                <div className="divider"></div>
                <p>Zenith Digital Media © 2024</p>
            </div>
        </StyledFooterContainer>
    );
};

export default Footer;
