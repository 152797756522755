import React from "react";
import { StyledServices } from "./styles";
import { StaticSiteImage, UiUxImage } from "assets";
import { staticSiteDevServices, uiUxServices } from "./servicesConfig";
import { ServiceBlock, Button } from "components";
import OtherServices from "./OtherServices";
import { scrollToFooter } from "../../helpers";

const Services = () => {
    return (
        <StyledServices id="StyledServices">
            <div className="heading">
                <h2>Our Services</h2>
                <p>Design solutions to elevate your brand, from identity to digital marketing.</p>
            </div>
            <div className="static-site">
                <img src={StaticSiteImage} alt="Static Site Development" />
                <div className="static-site-info">
                    <h3>Static Site Development</h3>
                    <p>Customized design and layout tailored to your brand identity and target audience.</p>
                    <div className="static-blocks">
                        {staticSiteDevServices.map((service) => (
                            <ServiceBlock key={service.title} info={service} />
                        ))}
                    </div>
                </div>
            </div>
            <div className="uiux">
                <img src={UiUxImage} alt="UI/UX Expertise" />
                <div className="uiux-info">
                    <h3>UI/UX Expertise</h3>
                    <p>Customized design and layout tailored to your brand identity and target audience.</p>
                    <div className="uiux-blocks">
                        {uiUxServices.map((service) => (
                            <ServiceBlock key={service.title} info={service} />
                        ))}
                    </div>
                </div>
            </div>
            <Button onClick={scrollToFooter} label="Get in touch" className="contact-button" />
            <OtherServices />
        </StyledServices>
    );
};

export default Services;
