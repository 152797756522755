import { Home } from "pages";
import { Navbar, Footer } from "components";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { ThemeProvider } from "styled-components";
import { light, dark } from "./themes";
import GlobalStyle from "./GlobalStyles";
import { useSelector, useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { setLightTheme, setDarkTheme } from "./actions";

const App = () => {
    const [theme, setTheme] = useState("light");
    const dispatch = useDispatch();

    const themeStyle = useSelector((state) => state.theme.style);
    const cookieTheme = Cookies.get("theme");

    // if cookie theme is set, set the page theme to the cookie theme
    // else set the page theme to the theme style in the redux store
    const setPageTheme = () => {
        if (cookieTheme) {
            setTheme(cookieTheme);

            if (cookieTheme === "light") dispatch(setLightTheme());
            else dispatch(setDarkTheme());
        } else {
            setTheme(themeStyle);
        }
    };

    useEffect(() => {
        setPageTheme();
    }, [themeStyle]);

    return (
        <>
            <ThemeProvider theme={theme === "light" ? light : dark}>
                <GlobalStyle />
                <span id="home"></span>
                <Navbar />
                <Home />
                <Footer />
                <ToastContainer />
            </ThemeProvider>
        </>
    );
};

export default App;
