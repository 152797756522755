import React, { useState, useRef } from "react";
import { StyledContact } from "./styles";
import { Button } from "../../components";
import emailjs from "@emailjs/browser";
import { AiOutlineSync } from "react-icons/ai";
import { capitalizeFirstLetter } from "../../helpers";
import { toast } from "react-toastify";

const Contact = () => {
    const [errors, setErrors] = useState({});
    const [emailSent, setEmailSent] = useState("");
    const formRef = useRef();

    const [form, setForm] = useState({
        user_name: "",
        user_email: "",
        message: "",
    });

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    const validateField = (name, value) => {
        const errors = {};
        let fieldName = "";

        switch (name) {
            case "user_name":
                fieldName = "Name";
                break;
            case "user_email":
                fieldName = "Email";
                break;
            default:
                fieldName = "Message";
                break;
        }

        if (value.length === 0) {
            errors[name] = `${capitalizeFirstLetter(fieldName)} is required`;
        }

        if (value.length > 0 && name === "user_email") {
            if (!validateEmail(value)) {
                errors.user_email = "Email is invalid";
            }
        }

        return errors;
    };

    const updateButtonLabel = () => {
        switch (emailSent) {
            case "sending":
                return <AiOutlineSync className={"spinner sync-icon"} fill="#fff" />;
            default:
                return "Send Message";
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm({
            ...form,
            [name]: value,
        });

        const newErrors = validateField(name, value);

        setErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors, ...newErrors };
            if (value.length > 0) {
                delete updatedErrors[name];
            }
            return updatedErrors;
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const allErrors = Object.keys(form).reduce((acc, field) => {
            const fieldErrors = validateField(field, form[field]);
            return { ...acc, ...fieldErrors };
        }, {});

        setErrors(allErrors);

        if (Object.keys(allErrors).length === 0) {
            setEmailSent("sending");
            emailjs
                .sendForm("service_m6ww30m", "template_qf2tw16", formRef.current, {
                    publicKey: "MS_YKM-310fqWSIo1",
                })
                .then(
                    () => {
                        setEmailSent("success");
                        setTimeout(() => {
                            setForm({
                                user_name: "",
                                user_email: "",
                                message: "",
                            });
                        }, 2500);
                        toast.success("Message sent successfully!", {
                            position: "bottom-center",
                            autoClose: 2500,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: false,
                            theme: "colored",
                        });
                    },
                    (error) => {
                        setEmailSent("error");
                        setTimeout(() => {
                            setForm({
                                user_name: "",
                                user_email: "",
                                message: "",
                            });
                        }, 3500);
                        toast.error("Error sending message, please try again.", {
                            position: "bottom-center",
                            autoClose: 3500,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: false,
                            theme: "light",
                        });
                    }
                );
        }
    };

    return (
        <StyledContact id="StyledContact">
            <div className="contact-area" id="contact">
                <h2 className="contact-header">Let's make something great together</h2>
                <form ref={formRef} className="contact-form" onSubmit={handleSubmit}>
                    <div className="row-1">
                        <div className="name-area">
                            <label className="input-label" htmlFor="user_name">
                                Name
                            </label>
                            <input
                                type="text"
                                name="user_name"
                                placeholder="John Doe"
                                className="contact-form-input"
                                value={form.user_name}
                                onBlur={() => setErrors(validateField("user_name", form.user_name))}
                                onChange={handleChange}
                            />
                            {errors.user_name && <span className="error">{errors.user_name}</span>}
                        </div>
                        <div className="name-area">
                            <label className="input-label" htmlFor="user_email">
                                Email
                            </label>
                            <input
                                type="text"
                                name="user_email"
                                placeholder="john.doe@email.com"
                                className="contact-form-input"
                                value={form.user_email}
                                onBlur={() => setErrors(validateField("user_email", form.user_email))}
                                onChange={handleChange}
                            />
                            {errors.user_email && <span className="error">{errors.user_email}</span>}
                        </div>
                    </div>
                    <div className="name-area message-box">
                        <label className="input-label" htmlFor="message">
                            Message
                        </label>
                        <textarea
                            onChange={handleChange}
                            value={form.message}
                            onBlur={() => setErrors(validateField("message", form.message))}
                            name="message"
                            placeholder="Hi! I would like to discuss..."
                            className="contact-form-input text-area"
                        />
                        {errors.message && <span className="error">{errors.message}</span>}
                    </div>
                    <Button
                        id="contact-form-button"
                        type="submit"
                        className={`contact-form-button ${Object.keys(errors).length > 0 ? "disabled" : ""}`}
                        label={updateButtonLabel()}
                    />
                </form>
            </div>
        </StyledContact>
    );
};

export default Contact;
