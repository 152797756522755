import { HiOutlineDevicePhoneMobile } from "react-icons/hi2";
import { BiImageAdd, BiTestTube } from "react-icons/bi";
import { GiSpeedometer } from "react-icons/gi";
import { AiOutlineUser } from "react-icons/ai";
import { PiChalkboardTeacher } from "react-icons/pi";
import { VscPreview } from "react-icons/vsc";
import { HiMiniMagnifyingGlass } from "react-icons/hi2";
import { GoGear } from "react-icons/go";
import { FiBarChart2 } from "react-icons/fi";
import { PiPenNib } from "react-icons/pi";
import { TbWorldWww } from "react-icons/tb";

const staticSiteDevServices = [
  {
    icon: HiOutlineDevicePhoneMobile,
    title: "Mobile Friendly Design",
    description:
      "Responsive and mobile-friendly development for seamless user experience across all devices.",
  },
  {
    icon: BiImageAdd,
    title: "Interactive Elements",
    description:
      "Integration of essential features and functionalities, such as contact forms, image galleries, and interactive elements.",
  },
  {
    icon: GiSpeedometer,
    title: "Performance and SEO",
    description:
      "Optimized loading speeds and performance for enhanced user satisfaction and SEO benefits.",
  },
];

const uiUxServices = [
  {
    icon: AiOutlineUser,
    title: "User-centric Design",
    description:
      "User-centered design approach focused on intuitive navigation and clear information architecture.",
  },
  {
    icon: PiChalkboardTeacher,
    title: "Appealing Interfaces",
    description:
      "Attention to detail in visual design, typography, and colour schemes to create visually appealing interfaces.",
  },
  {
    icon: BiTestTube,
    title: "User Testing",
    description:
      "User testing and feedback integration to refine designs and improve overall user experience.",
  },
];

const otherServices = [
  {
    icon: VscPreview,
    title: "CMS Integration Solutions",
    description: [
      "Ongoing maintenance and updates to ensure CMS security, stability, and performance.",
      "Support for content creators and administrators to effectively manage and update website content.",
      "CMS functionality that offers robust, real-time site updates, enabling immediate content changes without downtime for enhanced user engagement and agility.",
    ],
  },
  {
    icon: HiMiniMagnifyingGlass,
    title: "SEO Implementation",
    description: [
      "Keyword research and analysis to identify target keywords and optimize website content.",
      "On-page SEO optimization including meta tags, headings, and image alt texts for improved search engine visibility.",
      "Technical SEO enhancements such as site speed optimization, mobile responsiveness, and URL refinement.",
      "Regular monitoring and reporting of SEO performance metrics to track progress and adjust strategies as needed.",
    ],
  },
  {
    icon: GoGear,
    title: "Website Maintenance Services",
    description: [
      "Content updates, additions, and revisions to keep website information accurate and up to date.",
      "Performance monitoring and optimization to ensure fast loading times and smooth user experience.",
      "Bug fixes and troubleshooting to address any technical issues and ensure stable website functionality.",
    ],
  },
  {
    icon: FiBarChart2,
    title: "Web Analytics & Monitoring",
    description: [
      "Implementation of robust analytics tools such as Google Analytics to track website traffic, user behavior, and conversion metrics.",
      "Customized dashboard and reporting setup to monitor key performance indicators and track progress towards business goals.",
      "Analysis of website data to identify trends, opportunities, and areas for optimization.",
    ],
  },
  {
    icon: PiPenNib,
    title: "Brand Identity Design",
    description: [
      "Collaborative design process to develop a unique and memorable brand identity that resonates with your target audience.",
      "Creation of custom logos that encapsulate your brand's values, personality, and differentiation in the market.",
      "Development of brand style guides to maintain consistency across all brand touchpoints and communication channels.",
      "Business card design that reflects your brand identity and leaves a lasting impression on potential customers and partners.",
    ],
  },
  {
    icon: TbWorldWww,
    title: "Hosting & Domain Registration",
    description: [
      "Reliable and secure website hosting services with guaranteed uptime and data protection.",
      "Flexible hosting plans to accommodate varying traffic levels and storage requirements.",
      "Domain registration services to secure your brand's online identity and establish a professional web presence.",
    ],
  },
];

export { staticSiteDevServices, uiUxServices, otherServices };
