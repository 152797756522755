import React from "react";
import { MdSunny } from "react-icons/md";
import { IoMdMoon } from "react-icons/io";
import { useSelector, useDispatch } from "react-redux";
import StyledThemeSwitcher from "./styles";
import { setLightTheme, setDarkTheme } from "../../actions";
import Cookies from "js-cookie";

const ThemeSwitcher = ({ className }) => {
    const theme = useSelector((state) => state.theme.style);
    const dispatch = useDispatch();

    const changeTheme = (e) => {
        e.preventDefault();
        switch (theme) {
            case "light":
                dispatch(setDarkTheme());
                Cookies.set("theme", "dark", { expires: 365 });
                break;
            default:
                dispatch(setLightTheme());
                Cookies.set("theme", "light", { expires: 365 });
                break;
        }
    };

    return (
        <StyledThemeSwitcher onClick={changeTheme} className={className}>
            <div className="theme-switcher">{theme === "light" ? <IoMdMoon /> : <MdSunny />}</div>
        </StyledThemeSwitcher>
    );
};

export default ThemeSwitcher;
