import styled from "styled-components";

const StyledThemeSwitcher = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;

	border: none;

	width: 30px;
	height: 30px;

	background: ${(props) => props.theme.themeSwitcherBackground};
	box-shadow: ${(props) => props.theme.themeSwitcherBoxShadow};
	border-radius: 50%;
	transition: all 0.3s ease-in-out;

	&:hover {
		cursor: pointer;
		background: ${(props) => props.theme.themeSwitcherBackgroundHover};
	}

	.theme-switcher {
		display: flex;
		justify-content: center;
		align-items: center;

		color: ${(props) => props.theme.themeSwitcherIcon};

		svg {
			width: 20px;
			height: 20px;
		}
	}
`;

export default StyledThemeSwitcher;
