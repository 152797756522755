import styled from "styled-components";

const StyledHero = styled.div`
	height: 100vh;
	width: 100%;

	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

	background: linear-gradient(
			0deg,
			rgba(0, 0, 0, 0.5) 0%,
			rgba(0, 0, 0, 0.5) 100%
		),
		${({ $bgimage }) => $bgimage} lightgray 50% / cover no-repeat;

	display: flex;
	justify-content: center;
	align-items: center;

	.text {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		color: #fff;
		max-width: 330px;

		h1 {
			margin: 0;
			text-align: center;
			font-size: 30.395px;
			font-style: normal;
			font-weight: 800;
			line-height: 46.226px;
		}

		h3 {
			margin: 0;
			text-align: center;
			font-size: 20.263px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}

		.cta-button {
			margin-top: 20px;

			.label {
				font-size: 14px;
			}
		}
	}

	@media (min-width: 768px) {
		.text {
			max-width: 520px;

			h1 {
				font-size: 48px;
				line-height: 73px;
			}

			h3 {
				font-size: 32px;
			}
		}
	}

	@media (min-width: 1280px) {
		.text {
			max-width: 1160px;

			h1 {
				font-size: 64px;
				line-height: normal;
			}

			h3 {
				font-size: 36px;
			}
		}
	}
`;

export { StyledHero };
