export const light = {
    body: "#E3F7FF",

    ctaButton: "#0E6BA8",
    ctaButtonText: "#fff",
    ctaButtonHover: "#00072D",

    aboutUsText: "#001c55",
    aboutBoxShadow: "0px 9.284px 34.233px 0px rgba(0, 28, 85, 0.1)",

    servicesHeadings: "#001c55",
    servicesText: "#001c55",

    contactInputBorder: "#0e6ba8",
    contactInputBackground: "#a6e1fa",
    contactInputLabel: "#001c55",
    contactHeader: "#001c55",
    contactInputText: "#001c55",

    serviceBlockBackground: "#a6e1fa",
    serviceBlockBoxShadow: "0px 8px 29.5px 0px rgba(0, 28, 85, 0.1)",
    serviceBlockIcon: "#001c55",
    serviceBlockText: "#001c55",

    otherServiceBlockBackground: "#a6e1fa",
    otherServiceBlockBoxShadow: "0px 8px 29.5px 0px rgba(0, 28, 85, 0.1)",
    otherServiceBlockIcon: "#001c55",
    otherServiceBlockText: "#001c55",

    footerText: "#001c55",
    footerDivider: "#001c55",
    footerSocialLink: "#001c55",
    footerMainColor: "#001c55",

    logoColor: "#001c55",

    themeSwitcherBackground: "#fff",
    themeSwitcherBackgroundHover: "#001C55",
    themeSwitcherIcon: "#0E6BA8",
    themeSwitcherBoxShadow: "0px 8px 29.5px 0px rgba(0, 28, 85, 0.1)",

    backToTopBackground: "#0E6BA8",
    backToTopBackgroundHover: "#00072D",
    backToTopIcon: "#fff",
    backToTopBoxShadow: "0px 8px 29.5px 0px rgba(0, 28, 85, 0.1)",

    toastSuccessBackground: "#0E6BA8",
    toastSuccessText: "#fff",
    toastSuccessProgressBar: "#00072D",
};
