import React from "react";
import { StyledAbout } from "./styles";
import { AboutImage } from "assets";
import { Button } from "components";
import { scrollToServices } from "../../helpers";

const About = () => {
    return (
        <StyledAbout id="StyledAbout">
            <div className="container">
                <div className="image" id="about">
                    <img src={AboutImage} alt="About" />
                </div>

                <div className="text">
                    <h1>We combine design, thinking and craft.</h1>
                    <p>
                        At Zenith Digital Media, we transform visions into compelling designs that elevate brands. Our talented team of designers and
                        strategists collaborates to create customized solutions that resonate with your audience and drive success. From brand
                        identity to web design, we deliver exceptional results tailored to your unique needs.
                    </p>
                    <p>
                        We pride ourselves on our creativity and commitment to excellence, ensuring each project exceeds expectations. Whether you're
                        a startup or an established business, Zenith Digital Media is here to help you stand out with impactful, stunning visuals. Let
                        us bring your ideas to life.
                    </p>
                    <div className="cta-button">
                        <Button label="Our Services" onClick={scrollToServices} size={10} />
                    </div>
                </div>
            </div>
        </StyledAbout>
    );
};

export default About;
