import React from "react";
import { StyledHome } from "./styles";
import { Hero, About, Services } from "views";
import { BackToTop } from "../../components";

const Home = () => {
    return (
        <StyledHome id="StyledHome">
            <span id="home"></span>
            <Hero />
            <About />
            <span id="services"></span>
            <Services />
            <BackToTop />
        </StyledHome>
    );
};

export default Home;
