/**
 * @method setDarkTheme
 */
export const setDarkTheme = () => {
	return {
		type: "SET_DARK_THEME",
		payload: "dark",
	};
};

/**
 * @method setLightTheme
 */
export const setLightTheme = () => {
	return {
		type: "SET_LIGHT_THEME",
		payload: "light",
	};
};
