import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${(props) => props.theme.body};
  }


  .Toastify__toast--success {
    background: ${(props) => props.theme.toastSuccessBackground} !important;
    color: ${(props) => props.theme.toastSuccessText} !important;
  }

  .Toastify__progress-bar-theme--colored.Toastify__progress-bar--success {
    background: ${(props) => props.theme.toastSuccessProgressBar} !important;
  }

   #StyledFooter, #StyledServices, #about {
        scroll-margin-top: 5rem;
    }

  
  @media screen and (min-width: 768px) {
    #StyledFooter {
      scroll-margin-top: 5rem;
    }
  }
`;

export default GlobalStyle;
