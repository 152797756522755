import styled from "styled-components";

const StyledNavbar = styled.nav`
    --slide-distance: 246px;

    .top-nav-container {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 20;
        width: calc(100vw - 32px);
        height: 38px;
        padding: 12px 16px;
        background: rgba(10, 36, 114, 0.85);

        display: flex;
        justify-content: center;
        align-items: center;

        .top-nav {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            img {
                height: 38px;
            }

            .desktop-links {
                display: none;
            }

            .contact-btn {
                display: none;
            }

            .menu-btn {
                display: block;
            }
        }
    }

    .button-container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 12px;
    }

    .nav-menu-container {
        position: fixed;
        top: 62px;
        right: 0;
        z-index: 19;
        width: calc(246px - 36px);
        height: calc(100vh - 62px - 60px);
        padding: 38px 18px 22px;
        background: rgba(10, 36, 114, 0.85);
        transition: all 0.4s ease-out;

        .nav-menu {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-end;

            .mobile-links {
                width: 91px;
                height: 174px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-end;

                a {
                    text-decoration: none;
                    color: #fff;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    cursor: pointer;
                    padding: 21px 0;
                    border-bottom: 2px solid #fff;

                    &:hover {
                        color: #ddd;
                    }

                    &:first-child {
                        padding-top: 0;
                    }

                    &:last-child {
                        border-bottom: none;
                        padding-bottom: 0;
                    }
                }

                .divider {
                    width: 100%;
                    height: 1px;
                    background: #fff;
                }
            }
        }

        &.open {
            margin-right: 0;
        }

        &.closed {
            margin-right: -250px;
        }
    }

    .contact-btn .label {
        font-size: 12px;
    }

    @media (min-width: 768px) {
        --slide-distance: 403px;
        .top-nav-container {
            height: 52px;
            width: calc(100vw - 42px);
            padding: 6px 21px;
        }

        .nav-menu-container {
            top: 64px;
            width: calc(403px - 44px);
            height: calc(100vh - 64px - 58px);
            padding: 32px 22px 26px;
        }

        .open {
            margin-right: 0;
        }

        .closed {
            margin-right: calc(44px - 450px) !important;
        }
    }

    @media (min-width: 1280px) {
        .top-nav-container {
            height: 52px;
            width: calc(100vw - 180px);
            padding: 14px 90px;

            .top-nav {
                max-width: 1548px;

                img {
                    height: 44px;
                }

                .desktop-links {
                    width: 591px;
                    padding: 18px 27px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 135px;

                    a {
                        text-decoration: none;
                        color: #fff;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        cursor: pointer;

                        &:hover {
                            color: #ddd;
                        }
                    }
                }

                .contact-btn {
                    display: block;
                }

                .menu-btn {
                    display: none;
                }
            }
        }

        .nav-menu-container {
            display: none;
        }
    }
`;

export { StyledNavbar };
