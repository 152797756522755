import styled from "styled-components";

const StyledServiceBlock = styled.div`
	border-radius: 14px;
	background-color: ${(props) => props.theme.serviceBlockBackground};
	width: calc(100% - 84px);
	padding: 25px 42px;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	gap: 12px;
	box-shadow: ${(props) => props.theme.serviceBlockBoxShadow};

	.icon {
		background-color: #fff;
		border-radius: 50px;
		height: 50px;
		width: 50px;
		display: flex;
		justify-content: center;
		align-items: center;
		color: ${(props) => props.theme.serviceBlockIcon};
	}

	h3 {
		margin: 0 !important;
		text-align: center !important;
		font-size: 20px !important;
		font-style: normal !important;
		font-weight: 600 !important;
		line-height: normal !important;
	}

	p {
		margin: 0 !important;
		text-align: center !important;
		font-size: 14px !important;
		font-style: normal !important;
		font-weight: 400 !important;
		line-height: normal !important;
	}

	h3,
	p {
		color: ${(props) => props.theme.serviceBlockText};
	}

	@media (min-width: 768px) {
		width: calc(100% - 14px);
		padding: 18px 7px;

		h3 {
			font-size: 16px !important;
		}

		p {
			font-size: 12px !important;
		}
	}

	@media (min-width: 1280px) {
		width: calc(100% - 48px);
		padding: 24px 7px;

		h3 {
			font-size: 16px !important;
		}

		p {
			font-size: 14px !important;
		}
	}
`;

export { StyledServiceBlock };
